import axios, { AxiosResponse } from 'axios';

import { PlaybackConfig, PlayersConfiguration, RawPlayer, VideoDetails } from './model/playback.model';
import { BasePlayer, CreatePlayOptions, CreatePlaylistOptions, PlayerOptions, playerMapping } from './model/player.model';
import { playbackEndpoint, playerEndpoint } from './consts/api.consts';
import { EXPIRED_STATUS_CODE, TOKEN_PREFIX } from './consts/http.consts';
import { ErrorType, PlaybackErrorName, SDKErrorName } from './model/error.model';
import { constructErrorObject } from './utils/error.utils';
import { PlayerEventManager } from './player-events';
import { PlaybackEvents, BasePlaybackEvent } from './model/events.model';
import { Playlist } from './playlist/playlist';
import { PlaylistController } from './model/playlist.model';

/**
 * Playback SDK entry class representing video playback operations with customised features such as autoplay, muting etc.
 *
 * The class provides two ways to play a video to allow for client flexibility, namely, static or instance calls.
 * Both techniques require this class 'Player' to be initialised first and this requires the client API Key.
 * Once the 'Player' is initialised you may choose to play a video using any of the following patterns.
 *
 * @example <caption>Usage - Static Approach</caption>
 *
 * // Initialize the Playback SDK with your client API key:
 * //    also optionally pass over any playback options you desire to be applied to every video played.
 * Playback.initialize('client-api-key', { autoplay: true, muted: true });
 *
 * // Identity the video to play by the entry identifier:
 * //  - Provide the HTML element container identifier:
 * //     e.g. <div id="player"></div> so pass over 'player'.
 * //  - Provide the client access token:
 * //     this is required to access the protected resources.
 * const playOptions = {
 *   container: 'player',
 *   entryId: '0_xxxxxxxx',
 *   token: 'access_token',
 *   // optional playback options to be passed to the player which will overwrite those passed during initialization if any
 *   options: {
 *     autoplay: true,
 *     muted: true
 *   }
 * };
 *
 * // Play the video (this example uses the 'static' calling approach)
 * Playback.play(playOptions)
 *  .catch((error) => {
 *    // Handle any unexpected error as you desire.
 *    console.log('error playing the video:', error);
 *  });
 *
 * @example <caption>Usage - Instance Approach</caption>
 *
 * // Initialize the Playback SDK with your client API key:
 * //    also pass over any playback options you desire (or none) to be applied to every player instance.
 * Playback.initialize('client-api-key', { autoplay: true, muted: true });
 *
 * // Initialize the player instance where:
 * //   playerContainerId - html container id where the player will reside
 * //   { autoplay: true, muted: true } - optional player options which will overwrite those passed during SDK initialization if any
 *  Playback.player(playerContainerId, { autoplay: true, muted: true })
 *    .then((player) => {
 *      // Play the video where:
 *      //  entryId - video identifier
 *      //  token - client access token
 *      //  { autoplay: true, muted: true } - optional player options for specific video
 *      //                                    which will overwrite previously defined options (during SDK or player initialization)
 *      return player.play(entryId, token, { autoplay: true, muted: true });
 *    })
 *    .catch((error) => {
 *      // Handle any unexpected error as you desire.
 *      console.log('error playing the video:', error);
 *    });
 */
export class Playback {
  /** API Key of the client */
  public static apiKey: string;

  private static players: Record<string, BasePlayer> = {};
  private static defaultOptions = {} as PlayerOptions;
  private static playerOptions: Record<string, PlayerOptions> = {};
  private static playerConfig: Promise<PlayersConfiguration>;
  private static playbackConfigs: Record<string, PlaybackConfig> = {};
  private readonly containerId: string;
  private static events = new WeakMap<BasePlayer, PlayerEventManager>();
  private static _playlist?: Playlist | null = null;
  private _playlist?: Playlist | null = null;

  /**
   * Static consumer getter to be able to manipulate playlist
   */
  public static get playlist(): PlaylistController | null {
    if (!this._playlist) {
      throw new Error("Playlist is not initialized. Ensure a playlist is set before accessing the controller.");
    }

    return this.createPlaylistController(this._playlist);
  }

  /**
   * Instance consumer getter to be able to manipulate playlist
   */
  public get playlist(): PlaylistController | null {
    return this._playlist ? Playback.createPlaylistController(this._playlist) : null;
  }

  /**
   * Construct an instance of the Playback SDK (private constructor).
   *
   * @param {string} containerId initialize the player in the container with the provided container identifier.
   */
  private constructor(containerId: string) {
    this.containerId = containerId;
  }

  /**
   * Initialize the Playback SDK by passing over a client API key and player capabilities as options.
   * This should be the first call any client must make with the SDK in order to setup video playback.
   *
   * @example <caption>Initialising the Playback SDK</caption>
   *
   * Playback.initialize(<client-api-key>, { autoplay: true, muted: true });
   *
   * @param {string} apiKey API key of the client
   * @param {PlayerOptions} [options] capabilities that apply to all players if provided e.g. autoplay.
   * @throws {@link Error}
   * @returns {void}
   * @static static level class invocation so invoke as Playback.initialise(...)
   */
  public static initialize(apiKey: string, options?: PlayerOptions): void {
    if (Playback.playerConfig) {
      return;
    }

    Playback.apiKey = apiKey;
    Playback.defaultOptions = options;
    Playback.playerConfig = Playback.getVideoPlayerConfiguration(Playback.apiKey);
  }

  /**
   * Create an instance of the player in a specified container configured with certain capabilities (as requested).
   *
   * @example <caption>Create an Instance of a Player</caption>
   *
   * const playback = await Playback.player('player', { autoplay: true, muted: true });
   *
   * @param {string} containerId container identifier in which to initialize the player.
   * @param {PlayerOptions} [options] options that apply to this specific player instance (e.g. autoplay) and overwrite those passed during initialization if any.
   * @throws {@link Error}
   * @returns {Promise<Playback>} instance of a player.
   * @static
   */
  public static async player(containerId: string, options?: PlayerOptions): Promise<Playback> {
    const config = await Playback.playerConfig;

    if (!config || !Object.keys(config).length) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.SdkNotInitialized,
        `SDK not initialized, player configuration empty`,
      );
    }

    const existingPlayer = Playback.players[containerId];

    if (existingPlayer && existingPlayer.isInitialized) {
      await Playback.destroy(containerId);

      delete Playback.players[containerId];
    }

    const player = Playback.getPlayerFromConfig(config);
    Playback.players[containerId] = player;

    Playback.events.set(player, new PlayerEventManager(config.defaults.player, player));
    Playback.playerOptions[containerId] = { ...Playback.defaultOptions, ...options };

    player.initializePlayer(containerId, Playback.playerOptions[containerId]);

    return new Playback(containerId);
  }

  /**
   * Play a video: the video is played in the container and with player options both specified during the
   * initialisation phase.
   *
   * @example <caption>Play Video</caption>
   *
   * await player.play('<video_identifier>', "<client-auth-token>", { autoplay: true, muted: true });
   *
   * @param {string} entryId identifier of the video to be played.
   * @param {string} authorizationToken authorization token of the client.
   * @param {PlayerOptions} [options] options specific to this entry (e.g. muted, autoplay etc) which will overwrite those defined during SDK or player initialization.
   * @throws {@link Error}
   * @returns {Promise<void>}
   */
  public async play(entryId: string, authorizationToken?: string, options?: PlayerOptions): Promise<void> {
    const player = Playback.players[this.containerId];
    const playerConfig = await Playback.playerConfig;

    if (!player) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlayerExists,
        `Failed to get player for the ${this.containerId} container id`,
      );
    }

    if (!entryId) {
      if (!playerConfig.featureFlags?.customErrorScreen) player.showError('Failed to play the video');
      else await Playback.destroy(this.containerId);

      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        'Failed to play entry - no entry id passed',
      );
    }

    if (authorizationToken && !authorizationToken.startsWith(TOKEN_PREFIX)) {
      authorizationToken = `${TOKEN_PREFIX}${authorizationToken}`;
    } else if (authorizationToken && authorizationToken.slice(TOKEN_PREFIX.length).length <= 0) {
      authorizationToken = undefined;
    }

    const playbackPayload = await this.getPlaybackPayload(entryId, authorizationToken, options);

    Playback.playbackConfigs[this.containerId] = playbackPayload;
    if (!Playback.playbackConfigs[this.containerId]) {
      if (!playerConfig.featureFlags?.customErrorScreen) player.showError('No playback information retrieved');
      else await Playback.destroy(this.containerId);

      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlaybackDetails,
        `Failed to play entry - no playback information retrieved`,
      );
    }

    const updatedPlayer = await player.playVideo(Playback.playbackConfigs[this.containerId], {
      ...Playback.playerOptions[this.containerId],
      ...options,
    });

    if (!updatedPlayer) return;

    Playback.players[this.containerId] = updatedPlayer;

    const eventManager = Playback.events.get(player);
    Playback.events.delete(player);
    Playback.events.set(updatedPlayer, eventManager);
    eventManager.refresh(updatedPlayer);
  }

  /**
   * Run a playlist: the video is played in the container and with player options both specified during the
   * initialization phase.
   *
   * @example <caption>Run Playlist</caption>
   *
   * const playlistOptions = {
   *   entryId: ['video1', 'video2'],
   *   token: "<client-auth-token>",
   * };
   *
   * await player.runPlaylist(playlistOptions);
   *
   * @param {CreatePlaylistOptions} createPlaylistOptions An object containing playlist details.
   * @throws {@link Error} If no valid player instance is found or required parameters are missing.
   * @returns {Promise<PlaylistController>} Returns an object with playlist controls (next, prev, goTo, getCurrentIndex, getCurrentEntry).
   */
  public async runPlaylist(createPlaylistOptions: CreatePlaylistOptions): Promise<PlaylistController> {
    const { entryId, token, options } = createPlaylistOptions;
    const player = Playback.players[this.containerId];
    const playerConfig = await Playback.playerConfig;

    if (!player) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlayerExists,
        `Failed to get player for the ${this.containerId} container id`,
      );
    }

    if (!entryId) {
      if (!playerConfig.featureFlags?.customErrorScreen) player.showError('Failed to play the video');
      else await Playback.destroy(this.containerId);

      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        'Failed to play entry - no entry id passed',
      );
    }

    let authorizationToken = token;
    if (authorizationToken && !authorizationToken.startsWith(TOKEN_PREFIX)) {
      authorizationToken = `${TOKEN_PREFIX}${authorizationToken}`;
    } else if (authorizationToken && authorizationToken.slice(TOKEN_PREFIX.length).length <= 0) {
      authorizationToken = undefined;
    }

    this._playlist = new Playlist({
      entryId,
      authorizationToken,
      playerInstance: player,
      playerOptions: options,
      loadConfigAndPlayVideo: this.loadConfigAndPlayVideo.bind(this),
    });

    await this._playlist.start();

    return Playback.createPlaylistController(this._playlist);
  }

  /**
   * Create an instance of a player in specified container and run a playlist with the provided identifier.
   *
   * * @example <caption>Run Playlist</caption>
   *
   * * const createPlaylistOptions = {
   *     container: 'player-1',
   *     entryId: ['<video-entry1>', '<video-entry2>'],
   *     token: '<client-auth-token>',
   *     events: {
   *        PlaybackPlayerReady: (event) => console.log(event)
   *     }
   * }
   *
   * // Creating a player instance and running a playlist.
   * const playback = await Playback.runPlaylist(createPlaylistOptions);
   *
   * @throws {@link Error}
   * @returns {Promise<Playback>}
   * @static
   * @param createPlaylistOptions
   */
  public static async runPlaylist(createPlaylistOptions: CreatePlaylistOptions): Promise<Playback> {
    const { container, entryId, token, options } = createPlaylistOptions;

    if (!container || !entryId) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        'Required parameters were not passed: containerId, entryId',
      );
    }

    const playback = await Playback.player(container, { ...Playlist.defaultPlayerOptions, ...options });

    const playerInstance = Playback.players[container];
    if (!playerInstance) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlayerExists,
        'No valid player instance found for the provided container',
      );
    }

    let authorizationToken = token;
    if (authorizationToken && !authorizationToken.startsWith(TOKEN_PREFIX)) {
      authorizationToken = `${TOKEN_PREFIX}${authorizationToken}`;
    } else if (authorizationToken && authorizationToken.slice(TOKEN_PREFIX.length).length <= 0) {
      authorizationToken = undefined;
    }

    this._playlist = new Playlist({
      entryId,
      authorizationToken,
      playerInstance,
      playerOptions: options,
      loadConfigAndPlayVideo: playback.loadConfigAndPlayVideo.bind(playback),
    });

    await this._playlist.start();

    return playback;
  }

  // partially its duplicate of Playback.play()
  // fow now used only in playlist functionality, passing options into playVideo() leads to multiple video containers
  private async loadConfigAndPlayVideo(
    entryId: string,
    authorizationToken: string,
    // options?: PlayerOptions,
  ): Promise<void> {
    const player = Playback.players[this.containerId];
    const playerConfig = await Playback.playerConfig;

    try {
      Playback.playbackConfigs[this.containerId] = await this.getPlaybackPayload(entryId, authorizationToken);
    } catch (error) {
      Playback.playbackConfigs[this.containerId] = error;
      player.unload();
      throw error;
    }

    if (!Playback.playbackConfigs[this.containerId]) {
      if (!playerConfig.featureFlags?.customErrorScreen) player.showError('No playback information retrieved');
      else await Playback.destroy(this.containerId);

      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlaybackDetails,
        `Failed to play entry - no playback information retrieved`,
      );
    }

    const updatedPlayer = await player.playVideo(Playback.playbackConfigs[this.containerId]);

    if (!updatedPlayer) return;

    Playback.players[this.containerId] = updatedPlayer;

    const eventManager = Playback.events.get(player);
    Playback.events.delete(player);
    Playback.events.set(updatedPlayer, eventManager);
    eventManager.refresh(updatedPlayer);
  }

  // wrapper for consumer so we provide access to playlist manipulation methods only
  private static createPlaylistController(controller: Playlist): PlaylistController {
    return {
      next: () => controller.next(),
      prev: () => controller.prev(),
      goTo: (entryId: string) => controller.goTo(entryId),
      getCurrentIndex: () => controller.getCurrentIndex(),
      getCurrentEntry: () => controller.getCurrentEntry(),
    };
  }

  /**
   * Get video details of currently played video
   *
   * @example <caption>Get video details</caption>
   *
   * Playback.initialize('client-api-key', {autoplay: true});
   *
   * const playOptions = {
   *   container: 'player',
   *   entryId: '0_xxxxxxxx',
   *   token: 'access_token'
   * };
   *
   * Playback.play(playOptions)
   *  .catch((error) => {
   *    // Handle any unexpected error as you desire.
   *    console.log('error playing the video:', error);
   *  });
   *
   * Playback.getVideoDetails('player');
   *
   * @throws {@link Error}
   * @returns {VideoDetails | undefined}
   * @static
   * @param containerId
   */
  public static getVideoDetails(containerId: string): VideoDetails | undefined {
    const videoDetails = Playback.playbackConfigs[containerId];

    if (!videoDetails) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoVideoDetails,
        `No video details retrieved for ${containerId} container id`,
      );
    }

    return {
      id: videoDetails.id,
      name: videoDetails.name,
      duration: +videoDetails.duration / 1000,
      description: videoDetails.description,
    };
  }

  /**
   * Get video details of currently played video
   *
   * @example <caption>Get video details</caption>
   *
   * Playback.initialize('client-api-key');
   *
   * const player = Playback.player('player');
   *
   * player.play('0_xxxxx', 'some-token');
   *
   * player.getVideoDetails();
   *
   * @throws {@link Error}
   * @returns {VideoDetails | undefined}
   */
  public getVideoDetails(): VideoDetails | undefined {
    return Playback.getVideoDetails(this.containerId);
  }

  /**
   * Get playback time in seconds for the currently played video.
   *
   * @example <caption>Get current time</caption>
   *
   * Playback.initialize('client-api-key', {autoplay: true});
   *
   * const playOptions = {
   *   container: 'player',
   *   entryId: '0_xxxxxxxx',
   *   token: 'access_token'
   * };
   *
   * Playback.play(playOptions)
   *  .catch((error) => {
   *    console.log('error playing the video:', error);
   *  });
   *
   * Playback.getCurrentTime('player');
   *
   * @param containerId
   * @throws {@link Error}
   * @returns {number | undefined}
   */
  public static getCurrentTime(containerId: string): number | undefined {
    const player = Playback.players[containerId];

    if (!player) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlayerExists,
        `No player exists for ${containerId} container id`,
      );
    }

    return player.getCurrentTime();
  }

  /**
   * Get playback time in seconds for the currently played video.
   *
   * @example <caption>Get current time</caption>
   *
   * Playback.initialize('client-api-key');
   *
   * const player = Playback.player('player');
   *
   * player.play('0_xxxxx', 'some-token');
   *
   * player.getCurrentTime();
   *
   * @throws {@link Error}
   * @returns {number | undefined}
   */
  public getCurrentTime(): number | undefined {
    return Playback.getCurrentTime(this.containerId);
  }

  /**
   * Get the playback configuration of a video to obtain metadata etc of the media entry.
   *
   * @param {string} entryId identifier of the video to get configuration of.
   * @param {string} authorizationToken authorization token of the client.
   * @param {PlayerOptions} [options] options specific to this entry e.g. muted, autoplay etc.
   * @returns {Promise<PlaybackConfig>} configuration object holding information about the video.
   * @private
   */
  private async getPlaybackPayload(
    entryId: string,
    authorizationToken?: string,
    options?: PlayerOptions,
  ): Promise<PlaybackConfig> {
    const player = Playback.players[this.containerId];
    const playerConfig = await Playback.playerConfig;

    try {
      return await Playback.getPlaybackConfig(Playback.apiKey, entryId, authorizationToken, options);
    } catch (error) {
      if (error.response?.status === EXPIRED_STATUS_CODE && options?.retrieveSessionToken) {
        const token = options.retrieveSessionToken();

        if (!token) {
          if (!playerConfig.featureFlags?.customErrorScreen) player.showError('No session token');
          else await Playback.destroy(this.containerId);

          throw constructErrorObject(
            ErrorType.SDKError,
            SDKErrorName.SessionTokenNotRetrieved,
            'Failed to retrieve session token',
          );
        }

        // we have a new token so make a recursive call but without the retrieveSessionToken option
        return this.getPlaybackPayload(entryId, token);
      } else {
        if (!playerConfig.featureFlags?.customErrorScreen)
          player.showError(error.message || 'No playback configuration');
        else await Playback.destroy(this.containerId);

        throw error;
      }
    }
  }

  /**
   * Create an instance of a player in specified container and play a video with the provided identifier.
   *
   * @example <caption> Create Player</caption>
   *
   * const createPlayOptions = {
   *     container: 'player-1',
   *     entryId: '<video-entry>',
   *     token: '<client-auth-token>',
   *     options: { muted: true, autoplay: true },
   *     events: {
   *        PlaybackPlayerReady: (event) => console.log(event)
   *     }
   * }
   *
   * // Creating a player instance and playing a video.
   * const playback = await Playback.play(createPlayOptions);
   *
   * @throws {@link Error}
   * @returns {Promise<Playback>}
   * @static
   * @param createPlayOptions
   */
  public static async play(createPlayOptions: CreatePlayOptions): Promise<Playback> {
    const { container, entryId, token, events, options } = createPlayOptions;

    if (!container || !entryId) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        'Required parameters were not passed: containerId, entryId',
      );
    }

    const player = await Playback.player(container, { ...Playback.defaultOptions, ...options });

    if (events) {
      Object.keys(events).forEach((eventKey: PlaybackEvents) => {
        player.on(eventKey, events[eventKey]);
      });
    }

    await player.play(entryId, token, { ...Playback.defaultOptions, ...options });

    return player;
  }

  /**
   * Subscribes to specified player event
   *
   * @example <caption> Create Player</caption>
   *
   * Playback.initialize('client-api-key');
   * const playback = Playback.player('player');
   * playback.on('PlaybackPlayerReady', event => console.log(event));
   *
   * @param {PlaybackEvents} event event consumer wants to subscribe to.
   * @param {(payload: BasePlaybackEvent) => unknown | Promise<unknown>} handler function that will be fired when specified event occurs receiving event payload as parameter.
   * @returns {void}
   */
  public on(event: PlaybackEvents, handler: (payload: BasePlaybackEvent) => unknown | Promise<unknown>): void {
    const eventManager = Playback.events.get(Playback.players[this.containerId]);

    eventManager.on(event, handler);
  }

  /**
   * Unsubscribes from specified player event
   *
   * @example <caption> Create Player</caption>
   *
   * Playback.initialize('client-api-key');
   * const playback = Playback.player('player');
   *
   * const eventHandler = event => console.log(event);
   * playback.on('PlaybackPlayerReady', eventHandler);
   * playback.off('PlaybackPlayerReady', eventHandler);
   *
   * @param {PlaybackEvents} event event consumer wants to unsubscribe from.
   * @param {(payload: BasePlaybackEvent) => unknown | Promise<unknown>} handler function instance that was passed as handler during previous subscription.
   * @returns {void}
   */
  public off(event: PlaybackEvents, handler: (payload: BasePlaybackEvent) => unknown | Promise<unknown>): void {
    const eventManager = Playback.events.get(Playback.players[this.containerId]);

    eventManager.off(event, handler);
  }

  /**
   * Get the instance of the underlying video player e.g. Bitmovin Player.
   *
   * @example <caption>Get the Video Player Instance</caption>

   * const rawPlayer = await playback.getRawPlayer();
   *
   * @returns {Promise<RawPlayer>} underlying player instance e.g. Bitmovin Player.
   */
  public async getRawPlayer(): Promise<RawPlayer> {
    const player = Playback.players[this.containerId];
    return player.getRawPlayer();
  }

  /**
   * Returns the underlying player e.g. the Bitmovin player. This is intended for clients that might wish to
   * manipulate this directly instead of the 'wrapped' player perhaps to access underlying features etc.
   *
   * @example <caption>Get the Video Player</caption>
   *
   * const playback = await Playback.getRawPlayer('player-1');
   *
   * @throws {@link Error}
   * @returns {Promise<RawPlayer>}
   * @param {string} containerId
   * @param {PlayerOptions} options
   * @returns {Promise<RawPlayer>}
   */
  public static async getRawPlayer(containerId: string, options?: PlayerOptions): Promise<RawPlayer> {
    if (!containerId) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        'Required parameter containerId was not passed',
      );
    }

    const player = await Playback.player(containerId, options);

    return player.getRawPlayer();
  }

  /**
   * Unload the player and delete all associated HTML elements and event handlers.
   *
   * @example <caption>Unload Player</caption>
   *
   * Playback.destroy(player);
   *
   * @throws {@link Error}
   * @return {void}
   * @static
   * @param containerId
   */
  public static async destroy(containerId: string): Promise<void> {
    console.debug('Destroying the player in container:', containerId);
    const player = Playback.players[containerId];

    if (!player || !player.isInitialized) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.PlayerNotInitialized,
        'Failed to destroy the player as it is not initialized',
      );
    }

    Playback._playlist = null;

    const eventManager = Playback.events.get(player);
    if (eventManager) {
      eventManager.dispose();
      Playback.events.delete(player);
    }

    await player.playerStop();
  }

  /**
   * Unload the player and delete all associated HTML elements and event handlers.
   *
   * @example <caption>Unload Player</caption>
   *
   * Playback.destroy(player);
   *
   * @throws {@link Error}
   * @return {void}
   * @static
   */
  public async destroy(): Promise<void> {
    this._playlist = null;
    await Playback.destroy(this.containerId);
  }

  /**
   * Mutes the player.
   *
   * @param {string} containerId container identifier
   * @static
   * @returns {void}
   */
  public static mute(containerId: string): void {
    const player = Playback.players[containerId];
    player.mute();
  }

  /**
   * Mutes the player.
   */
  public mute(): void {
    Playback.mute(this.containerId);
  }

  /**
   * Unmutes the player.
   */
  public unmute(): void {
    Playback.unmute(this.containerId);
  }

  /**
   * Unmutes the player.
   *
   * @param {string} containerId container identifier
   * @static
   * @returns {void}
   */
  public static unmute(containerId: string): void {
    const player = Playback.players[containerId];
    player.unmute();
  }

  /**
   * Calls playback API to get partner configuration
   *
   * @param {string} apiKey API key of the client
   * @param {string} entryId video entry id
   * @param {string} authorizationToken authorization token of the client
   * @returns {Promise<PlaybackConfig>} partner playback configuration
   * @static
   */
  private static async getPlaybackConfig(
    apiKey: string,
    entryId: string,
    authorizationToken: string,
    options?: PlayerOptions,
  ): Promise<PlaybackConfig> {
    const playbackUrl = `${process.env.PLAYBACK_URL}/${playbackEndpoint}/${entryId}`;

    let config: PlaybackConfig;

    try {
      config = await this.getConfiguration<PlaybackConfig>(playbackUrl, apiKey, authorizationToken, options);
    } catch (error) {
      if (error.response?.status === EXPIRED_STATUS_CODE) throw error;

      const errorData = error.response?.data;
      if (errorData?.status && errorData?.message && errorData?.reason) {
        throw constructErrorObject(
          errorData.reason === PlaybackErrorName.NoEntitlement ? ErrorType.EntitlementsError : ErrorType.PlaybackError,
          errorData.reason as PlaybackErrorName,
          errorData.message,
        );
      }

      throw constructErrorObject(
        ErrorType.PlaybackError,
        SDKErrorName.ConfigurationNotRetrieved,
        'Error retrieving playback configuration',
      );
    }
    return config;
  }

  /**
   * Calls playback API to get player configuration
   *
   * @param {string} apiKey API key of the client
   * @returns {Promise<PlaybackConfig>} partner playback configuration
   * @static
   */
  private static async getVideoPlayerConfiguration(apiKey: string): Promise<PlayersConfiguration> {
    const playbackUrl = `${process.env.PLAYBACK_URL}/${playerEndpoint}`;

    let player: PlayersConfiguration;

    try {
      player = await Playback.getConfiguration<PlayersConfiguration>(playbackUrl, apiKey);
    } catch (error) {
      throw constructErrorObject(
        ErrorType.PlaybackError,
        SDKErrorName.ConfigurationNotRetrieved,
        error.response?.data || 'Error retrieving player configuration',
      );
    }

    return player;
  }

  /**
   * Calls playback API to get configuration based on url passed for an entry
   *
   * @param playbackUrl endpoint to call specifying the entry in the path/route
   * @param {string} apiKey API key of the client
   * @param {string} [authorizationToken] authorization token of the client
   * @returns {Promise<PlaybackConfig>} partner playback configuration
   * @static
   */
  private static async getConfiguration<T>(
    playbackUrl: string,
    apiKey: string,
    authorizationToken?: string,
    options?: PlayerOptions,
  ): Promise<T> {
    const headers = {
      'x-api-key': apiKey,
      authorization: authorizationToken,
    };
    
    if (options?.subtenantId) {
      headers['subtenantId'] = options.subtenantId;
    }

    const response = await Playback.httpGet<T>(playbackUrl, undefined, headers);

    const authorization = response.headers['x-playback-token'];

    if (authorization) {
      return { ...response.data, jwt: authorization };
    }

    return response.data;
  }

  /**
   * Function using axios to make generic GET calls
   *
   * @param {string} baseUrl URL for the request
   * @param {Record<string, string>} [params] query parameters of the request
   * @param {Record<string, string>} [headers] headers of the request
   * @returns {Promise<T>}
   * @static
   */
  private static async httpGet<T>(
    baseUrl: string,
    params?: Record<string, string>,
    headers?: Record<string, string>,
  ): Promise<AxiosResponse<T>> {
    const requestConfig = {
      method: 'GET',
      url: baseUrl,
      params,
      headers,
    };

    return await axios(requestConfig);
  }

  private static getPlayerFromConfig(playerConfig: PlayersConfiguration): BasePlayer {
    const playerKey = playerConfig.defaults.player;

    if (playerKey) {
      const playerDetails = playerConfig.player[playerKey];

      if (playerDetails) {
        const videoPlayer = playerMapping[playerKey];
        return new videoPlayer(playerDetails);
      }

      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.NoPlayerExists,
        `Failed to find player ${playerKey} in players configuration`,
      );
    }
  }
}
