import { AdBreakConfig } from 'bitmovin-player';
import {
  PlaybackEvent,
  Player,
  PlayerAPI,
  PlayerConfig,
  PlayerEvent,
  PlayerEventBase,
  SourceConfig,
} from 'bitmovin-player/modules/bitmovinplayer-core';
import {
  Button,
  Container,
  UIContainer,
  SettingsPanelItem,
  SettingsPanelPage,
  VideoQualitySelectBox,
  PlaybackSpeedSelectBox,
  AudioQualitySelectBox,
  SettingsPanel,
  SubtitleSettingsPanelPage,
  SubtitleSelectBox,
  SettingsPanelPageOpenButton,
  SubtitleSettingsLabel,
  ControlBar,
  PlaybackTimeLabel,
  PlaybackTimeLabelMode,
  SeekBar,
  SeekBarLabel,
  SubtitleOverlay,
  UIManager,
  AirPlayToggleButton,
  BufferingOverlay,
  CastStatusOverlay,
  CastToggleButton,
  ErrorMessageOverlay,
  FullscreenToggleButton,
  PictureInPictureToggleButton,
  PlaybackToggleButton,
  PlaybackToggleOverlay,
  RecommendationOverlay,
  SettingsToggleButton,
  Spacer,
  TitleBar,
  VRToggleButton,
  VolumeSlider,
  VolumeToggleButton,
  Watermark,
  AudioTrackSelectBox,
} from 'bitmovin-player-ui';

import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';
import Crypto from 'bitmovin-player/modules/bitmovinplayer-crypto';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import NativeEngineModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import LowLatencyModule from 'bitmovin-player/modules/bitmovinplayer-lowlatency';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style';
import DrmModule from 'bitmovin-player/modules/bitmovinplayer-drm';
import AdvertisingCore, { AdTagType } from 'bitmovin-player/modules/bitmovinplayer-advertising-core';
import AdvertisingIma from 'bitmovin-player/modules/bitmovinplayer-advertising-ima';
import Analytics from 'bitmovin-player/modules/bitmovinplayer-analytics';
import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';

import { Advert, Integrations, PlaybackConfig, PlayerDetails, RawPlayer } from '../model/playback.model';
import { BasePlayer, PlayerOptions } from '../model/player.model';
import initBitmovinMux from '@mux/mux-data-bitmovin';
import { Resume } from '../resume/resume';
import { v4 as uuidv4 } from 'uuid';

import './styles.css';
import { constructErrorObject } from '../utils/error.utils';
import { ErrorType, SDKErrorName } from '../model/error.model';
import type { AnalyticsConfig } from 'bitmovin-player/types/analytics/ConfigAPI';

Player.addModule(EngineBitmovinModule); // Provides common adaptive streaming functionality
Player.addModule(NativeEngineModule);
Player.addModule(PolyfillModule); // Provides polyfills for legacy browsers which don't support state-of-the-art JavaScript features like Promise or String.prototype.includes
Player.addModule(Crypto); // Provides Decyption of AES encrypted content
Player.addModule(MseRendererModule); // State-of-the-art video rendering of DASH, HLS or Smooth using the browser's MediaSource Extension
Player.addModule(ContainerTSModule); // Provides support for trans-multiplexing MPEG-2 TS to fMP4
Player.addModule(ContainerMp4Module); // Provides support for playback of MP4 container formats in supported browsers
Player.addModule(StyleModule); // Provides basic styling of the player
Player.addModule(HlsModule); // Provides support for HLS playback
Player.addModule(XmlModule); // Handling of XML files, like DASH or VAST manifests
Player.addModule(LowLatencyModule); // Provides low latency live-streaming support
Player.addModule(DashModule); // Provides MPEG-DASH support
Player.addModule(AbrModule); // Provides the available Adaptive BitRate algorithms
Player.addModule(DrmModule); // Provides support for Widevine, PlayReady, PrimeTime and Fairplay DRM systems
Player.addModule(AdvertisingCore);
Player.addModule(AdvertisingIma);
Player.addModule(Analytics);

/**
 * Bitmovin video player implementation of a player for client integration.
 *
 * Bitmovin Player is a highly customizable video player that supports various streaming protocols, including HLS. It
 * offers advanced features such as adaptive bitrate streaming, low-latency playback, and DRM support.
 *
 * Exposure of this client e.g. the underlying player, is intended for advanced use by clients and is typically not
 * recommended unless you wish to utilise advanced native features of the player; instead it is recommended that clients
 * use the wrapper around the underlying player i.e. 'Playback' class, to perform supported video operations.
 *
 * @see Playback
 * @see https://bitmovin.com/video-player
 * @extends BasePlayer
 */
export class Bitmovin implements BasePlayer {
  private player: PlayerAPI;
  private playerConfig: PlayerConfig;
  private playerSourceConfig: SourceConfig;
  private readonly integration: Integrations;
  private adverts: AdBreakConfig[] = [];
  private eventsOfInterest = [
    PlayerEvent.Play,
    PlayerEvent.Playing,
    PlayerEvent.Paused,
    PlayerEvent.Seeked,
    PlayerEvent.PlaybackFinished,
  ];

  private UIManager: UIManager;
  private htmlContainer: HTMLElement;
  private errorMessageOverlay: ErrorMessageOverlay;
  private isAnalyticsInitialized = false;

  constructor(playerDetails: PlayerDetails) {
    this.playerConfig = {
      key: playerDetails.license,
      advertising: {},
      remotecontrol: {
        type: 'googlecast',
      },
      ...(playerDetails.adaptation ? { adaptation: playerDetails.adaptation } : {}),
      ...(playerDetails.buffer ? { buffer: playerDetails.buffer } : {}),
    };
    this.integration = playerDetails.integrations;
  }

  /**
   * Is the player initialized?
   *
   * @example <caption>Check if the player is initialized</caption>
   *
   * // Initialize the player in a container.
   * const player = new Bitmovin();
   * player.initializePlayer('player');
   *
   * // Check if the player is initialized ?
   * const isInitialized = player.isInitialized;
   *
   * @returns {boolean} boolean showing if the player is initialized
   */
  get isInitialized(): boolean {
    return !!this.player;
  }

  /**
   * Initializes the player in the specified container.
   *
   * @example <caption>Initialize a Bitmovin Video Player</caption>
   *
   * // Create an instance of the video player.
   * const player = new Bitmovin();
   *
   * // Initialize the player in an HTML container with id 'player'.
   * player.initializePlayer('player');
   *
   * @param {string} containerId id of the HTML container/element where the player will reside.
   * @param options video player features e.g autoplay.
   * @returns {void}
   */
  public initializePlayer(containerId: string, options?: PlayerOptions): void {
    if (!containerId) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        `Failed to initialize the player - no containerId provided`,
      );
    }

    this.htmlContainer = document.getElementById(containerId);
    if (!this.htmlContainer) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.PlayerNotInitialized,
        `Failed to initialize the player - no container found`,
      );
    }

    if (options) {
      this.playerConfig = this.getPlayerConfig(this.playerConfig, options);
    }

    this.player = new Player(this.htmlContainer, this.playerConfig);

    this.UIManager = new UIManager(this.player, this.createUIContainer(this.player, options));

    console.debug('Player initialized');
  }

  private createUIContainer(
    player: PlayerAPI,
    { forwardButtonClassName, rewindButtonClassName, seekInterval, showOverlay }: PlayerOptions = {},
  ): UIContainer {
    const interval = Number(seekInterval) || 20;

    const rewindButton = new Button({ cssClass: `ui-rewindbutton bmpui-ui-button ${rewindButtonClassName || ''}` });
    rewindButton.onClick.subscribe(function () {
      if (player.isLive()) player.timeShift(Math.max(player.getMaxTimeShift(), player.getTimeShift() - interval));
      else player.seek(Math.max(0, player.getCurrentTime() - interval));
    });

    const forwardButton = new Button({ cssClass: `ui-forwardbutton bmpui-ui-button ${forwardButtonClassName || ''}` });
    forwardButton.onClick.subscribe(function () {
      if (player.isLive()) player.timeShift(Math.min(0, player.getTimeShift() + interval));
      else player.seek(Math.min(player.getDuration(), player.getCurrentTime() + interval));
    });

    const subtitleOverlay = new SubtitleOverlay();

    const mainSettingsPanelPage = new SettingsPanelPage({
      components: [
        new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
        new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox()),
        new SettingsPanelItem('Audio Track', new AudioTrackSelectBox()),
        new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
      ],
    });

    const settingsPanel = new SettingsPanel({
      components: [mainSettingsPanelPage],
      hidden: true,
    });

    const subtitleSettingsPanelPage = new SubtitleSettingsPanelPage({
      settingsPanel: settingsPanel,
      overlay: subtitleOverlay,
    });

    const subtitleSelectBox = new SubtitleSelectBox();

    const subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
      targetPage: subtitleSettingsPanelPage,
      container: settingsPanel,
      ariaLabel: 'Subtitles',
      text: 'open',
    });

    mainSettingsPanelPage.addComponent(
      new SettingsPanelItem(
        new SubtitleSettingsLabel({
          text: 'Subtitles',
          opener: subtitleSettingsOpenButton,
        }),
        subtitleSelectBox,
        {
          role: 'menubar',
        },
      ),
    );

    settingsPanel.addComponent(subtitleSettingsPanelPage);

    const controlBar = new ControlBar({
      components: [
        settingsPanel,
        new Container({
          components: [
            new PlaybackTimeLabel({
              timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
              hideInLivePlayback: true,
            }),
            new SeekBar({ label: new SeekBarLabel() }),
            new PlaybackTimeLabel({
              timeLabelMode: PlaybackTimeLabelMode.TotalTime,
              cssClasses: ['text-right'],
            }),
          ],
          cssClasses: ['controlbar-top'],
        }),
        new Container({
          components: [
            rewindButton,
            new PlaybackToggleButton(),
            forwardButton,
            new VolumeToggleButton(),
            new VolumeSlider(),
            new Spacer(),
            new PictureInPictureToggleButton(),
            new AirPlayToggleButton(),
            new CastToggleButton(),
            new VRToggleButton(),
            new SettingsToggleButton({ settingsPanel: settingsPanel }),
            new FullscreenToggleButton(),
          ].filter(Boolean),
          cssClasses: ['controlbar-bottom'],
        }),
      ],
    });

    this.errorMessageOverlay = new ErrorMessageOverlay();

    return new UIContainer({
      components: [
        subtitleOverlay,
        new BufferingOverlay(),
        new PlaybackToggleOverlay(),
        new CastStatusOverlay(),
        controlBar,
        ...(showOverlay !== false ? [new TitleBar()] : []),
        new RecommendationOverlay(),
        new Watermark(),
        this.errorMessageOverlay,
      ],
    });
  }

  /**
   * Play video in the player.
   *
   * @param {PlaybackConfig} playbackConfig playback configuration (as retrieved from the Playback API).
   * @param {PlayerOptions} [options] video player features/options specific to this entry e.g. muted, autoplay etc.
   * @returns {Promise<Bitmovin | void>}
   */
  public async playVideo(playbackConfig: PlaybackConfig, options?: PlayerOptions): Promise<Bitmovin | void> {
    if (!playbackConfig) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.RequiredParametersNotPassed,
        `Failed to play video - no playback config received`,
      );
    }
    
    // TODO: add drm config here
    // Configure player source with playback details
    this.playerSourceConfig = {
      description: playbackConfig.description,
      title: playbackConfig.name,
      ...(playbackConfig.coverImg && {
        poster:
          this.htmlContainer?.offsetWidth > 1080
            ? playbackConfig.coverImg['1080']
            : this.htmlContainer?.offsetWidth > 720
            ? playbackConfig.coverImg['720']
            : playbackConfig.coverImg['360'],
      }),
      options: {
        ...(Object.values(playbackConfig.media).every((mediaProp) => {
          try {
            const manifestUrl = new URL(mediaProp);

            return manifestUrl.host.includes('akamaized.net');
          } catch {
            return true;
          }
        })
          ? {
              headers: {
                'X-STREAMAMG-PLAYER': `bitmovin-${playbackConfig.id}`,
              },
            }
          : {}),
        ...(playbackConfig.playFrom ? { startOffset: playbackConfig.playFrom } : {}),
      },
      ...playbackConfig.media,
    };

    // Merge custom headers from options, if provided via inalization
    if (options?.headers) {
      this.playerSourceConfig.options = {
        ...this.playerSourceConfig.options,
        headers: {
          ...(this.playerSourceConfig.options?.headers || {}),
          ...options.headers,
        },
      };
    }

    // Initialize the player if needed
    if (options || !this.player || this.hasToInitializeAnalytics) {
      this.prePlayerInitAnalyticsConfiguration(playbackConfig);

      this.playerConfig = this.getPlayerConfig(this.playerConfig, options);

      this.player = new Player(this.htmlContainer, this.playerConfig);

      if (this.UIManager) {
        this.UIManager.release();
      }

      this.UIManager = new UIManager(this.player, this.createUIContainer(this.player, options));
    }

    // Load player source and integrate playback config
    this.player.load(this.playerSourceConfig).then(() => {
      return this.addIntegration(playbackConfig);
    });

    return this;
  }

  /**
   * Get the current playback time in seconds.
   *
   * @returns {number | undefined}
   */
  public getCurrentTime(): number | undefined {
    if (!this.player) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.SdkNotInitialized,
        `Error retrieving current video time - player not initialized`,
      );
    }

    return this.player.getCurrentTime();
  }

  /**
   * Listen to player events by subscribing to the event specified.
   *
   * @param {PlayerEvent} event player event to listen for.
   * @param {PlayerEventBase} func function to be triggered by the arrival of an event of the type specified.
   * @returns {void}
   */
  public listenOn(event: PlayerEvent, func: (event: PlayerEventBase | PlaybackEvent) => void): void {
    if (!this.isInitialized) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.PlayerNotInitialized,
        `Failed to listen for event - no player initialized`,
      );
    }

    const key = Object.keys(PlayerEvent).find((x) => PlayerEvent[x] === event);

    if (key) {
      this.player.on(PlayerEvent[key], func);
    } else {
      throw `No recognised event type ${event}`;
    }
  }

  /**
   * Unsubscribe from specified event.
   *
   * @param {PlayerEvent} event player event to unsubscribe from.
   * @param {PlayerEventBase} func event handler provided to listenOn method previously
   * @returns {void}
   */
  public off(event: PlayerEvent, func: (event: PlayerEventBase | PlaybackEvent) => void): void {
    if (!this.isInitialized) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.PlayerNotInitialized,
        `Failed to unsubscribe from the event - no player initialized`,
      );
    }
    const key = Object.keys(PlayerEvent).find((x) => PlayerEvent[x] === event);
    if (key) {
      this.player.off(PlayerEvent[key], func);
    } else {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.UnknownParameter,
        `Failed to unsubscribe from the event - no recognised event type ${event}`,
      );
    }
  }

  /**
   * Stop the player and clean up player resources:
   *  e.g for a Bitmovin player it will unload the player and all associated HTML elements and event handlers.
   *
   * @returns {Promise<void>}
   */
  public async playerStop(): Promise<void> {
    if (!this.isInitialized) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.SdkNotInitialized,
        `Failed to stop player - no player initialized`,
      );
    }

    if (this.UIManager) {
      this.UIManager.release();
    }

    await this.player.destroy();

    this.player = null;
    this.isAnalyticsInitialized = false;

    // Ensure we close the player web socket after stopping the player (normal closure).
    Resume.onClosedEvent({ code: 1000, reason: 'closed as player stopped' });
    console.debug('Player stopped');
  }

  /**
   * Return the raw instance of the Bitmovin player.
   *
   * @returns {RawPlayer}
   */
  public getRawPlayer(): RawPlayer {
    if (!this.isInitialized) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.SdkNotInitialized,
        `Failed to find player - no player initialized`,
      );
    }
    return { type: 'bitmovin', player: this.player };
  }

  /**
   * Show error message overlay in the initialized player.
   *
   * @param {string} errorMessage
   * @returns {void}
   */
  public showError(errorMessage: string): void {
    if (!this.isInitialized || !this.errorMessageOverlay) {
      throw constructErrorObject(
        ErrorType.SDKError,
        SDKErrorName.SdkNotInitialized,
        `Failed to show error message overlay - no player initialized`,
      );
    }

    this.errorMessageOverlay.display(errorMessage);
  }

  /**
   *  Unloads the current video source. Keeps player container.
   */
  public async unload(): Promise<void> {
    await this.player.unload();
  }

  /**
   *  Mutes the player.
   */
  public mute(): void {
    this.player.mute();
    console.debug('Player muted');
  }

  /**
   * Unmutes the player.
   */
  public unmute(): void {
    this.player.unmute();
    console.debug('Player unmuted');
  }

  /**
   * Load player module.
   *
   * @private
   * @param {{ name: string }} module player module
   */
  private loadModule(module: { name: string }) {
    if (!Player.getModules().find((mod) => mod.toString() === module.name)) {
      console.debug('Adding module to the player:', module);
      Player.addModule(module);
    }
  }

  /**
   * Update player config based on integrations before player initialized.
   *
   * @private
   * @param {PlaybackConfig} playbackConfig playback configuration (as retrieved from the Playback API).
   */
  private prePlayerInitAnalyticsConfiguration(playbackConfig: PlaybackConfig): void {
    if (!this.integration?.analytics) {
      console.debug('Bitmovin Analytics not enabled. Skipping pre-player initialized setup');
      return;
    }

    console.debug('Applying pre-player initialized integrations');

    this.playerConfig = {
      ...this.playerConfig,
      analytics: this.getAnalyticsConfig(playbackConfig)
    };

    this.isAnalyticsInitialized = true;
  }

  /**
   * Checks the state of player, whether analytics integration required and already initialized
   * @private
   */
  private get hasToInitializeAnalytics(): boolean {
    return !!this.integration?.analytics?.key && !this.isAnalyticsInitialized;
  }
  
  /**
   * Provides with AnalyticsConfig for Bitmovin Analytics integration.
   *
   * @private
   * @param {PlaybackConfig} playbackConfig playback configuration (as retrieved from the Playback API).
   */
  private getAnalyticsConfig(playbackConfig: PlaybackConfig): AnalyticsConfig {
    const { key } = this.integration.analytics;

    // customerId logic duplicate from muxIntegration()
    let customerId = <string>playbackConfig.metadata?.customerId;

    if (!customerId) {
      const storedGuestId = localStorage.getItem('guestId');
      if (storedGuestId) {
        customerId = storedGuestId;
      } else {
        customerId = 'guest_' + uuidv4();
        localStorage.setItem('guestId', customerId);
      }
    }

    return {
      key,
      customUserId: customerId,
      videoId: playbackConfig.id,
      title: playbackConfig.name,
      cdnProvider: 'Akamai',
    };
  }

  /**
   * Add integrations to player.
   *
   * @private
   * @param playbackConfig
   */
  private async addIntegration(playbackConfig: PlaybackConfig): Promise<void> {
    if (playbackConfig.adverts) {
      await this.advertisingIntegration(playbackConfig);
    }
    console.debug('Integrations we are inside addIntegration');
    if (this.integration) {
      if (this.integration.mux && playbackConfig.media && !this.integration.analytics) {
        // Add mux player integration, if there is no analytics.
        console.debug('MUX integration added to player');
        this.muxIntegration(this.integration.mux, playbackConfig);
      }

      if (this.integration.resume && !!playbackConfig.jwt && !this.player?.isLive()) {
        console.debug('Integrations we are inside addIntegration');

        // Only add resume player integration for VOD streams.
        console.debug('Resume integration added to player for non-live stream');
        this.resumeIntegration(playbackConfig);
      }
    }
  }

  /**
   * Setup Mux integration.
   *
   * @param {Record<string, string>} mux
   * @param {PlaybackConfig} config
   * @private
   */
  private muxIntegration(mux: Record<string, string>, config: PlaybackConfig): void {
    // Check if customerId is null or undefined. If it is then generate a random UUID. So we can track FREE users

    // Retrieve the customerId from config.metadata, or use the stored guest ID if it exists,
    // or create a new guest UUID and store it in local storage.
    let customerId = config.metadata?.customerId;

    if (!customerId) {
      const storedGuestId = localStorage.getItem('guestId');
      if (storedGuestId) {
        customerId = storedGuestId;
      } else {
        customerId = 'guest_' + uuidv4();
        localStorage.setItem('guestId', customerId);
      }
    }

    console.debug('init mux with customerId:', customerId);

    const encoding = Object.keys(config.media)[0];
    const isLive = this.player.isLive();
    const playerInitTime = initBitmovinMux.utils.now();

    initBitmovinMux(
      this.player,
      {
        debug: false,
        data: {
          ...mux,
          player_version: this.player.version,
          player_name: config.metadata?.playerName || 'bitmovin player',
          player_init_time: playerInitTime,
          video_id: config.id,
          video_title: config.name,
          video_series: config.description,
          video_duration: config.duration,
          video_stream_type: isLive ? 'live' : 'on-demand',
          video_cdn: 'Akamai',
          video_encoding_variant: encoding,
          viewer_user_id: customerId,
        },
      },
      {
        player: {
          PlayerEvent,
        },
      },
    );
  }

  /**
   * Setup Resume integration.
   *
   * @private
   * @param playbackConfig
   */
  private resumeIntegration(playbackConfig: PlaybackConfig): void {
    Resume.connect(playbackConfig.jwt);
    console.debug('WebSocket connected:', Resume.connected);

    this.eventsOfInterest.forEach((eventName) => {
      // calling sendMessage method to each event of interest
      this.listenOn(eventName, (event) => {
        console.debug(`Binding to event: ${eventName}`);
        const msg = this.getTimestampMessage(playbackConfig, event);
        Resume.sendMessage(msg);
      });
    });
  }

  /**
   * Return the player config.
   *
   * @param {PlayerConfig} config
   * @param {PlayerOptions} options
   * @returns {PlayerConfig}
   * @private
   */
  private getPlayerConfig(config: PlayerConfig, options: PlayerOptions): PlayerConfig {
    return {
      ...config,
      playback: { ...config.playback, autoplay: options.autoplay, muted: options.muted },
    };
  }

  /**
   * Create a timestamp message.
   *
   * @param {PlaybackConfig} playbackConfig partner configuration retrieved from the Playback API
   * @param {PlaybackEvent | PlayerEventBase} event the event that triggered the message
   * @returns {string} the timestamp message
   * @private
   */
  private getTimestampMessage(playbackConfig: PlaybackConfig, event: PlaybackEvent | PlayerEventBase) {
    // 'event' is of type 'PlaybackEvent'
    return {
      action: 'recordTimestamp',
      timestamp: this.isPlaybackEvent(event) ? event.time : this.player.getCurrentTime(),
      duration: this.player.getDuration(),
      entryId: playbackConfig.id,
      lastActionAt: new Date().toISOString(),
      sourceEvent: event.type,
    };
  }

  /**
   * Checks if event is of type PlaybackEvent or PlayerEventBase.
   *
   * @param event {PlaybackEvent | PlayerEventBase} event to check.
   * @private
   * @return event
   */
  private isPlaybackEvent(event: PlaybackEvent | PlayerEventBase): event is PlaybackEvent {
    return (event as PlaybackEvent).time !== undefined;
  }

  /**
   * Configures adverts that are contain in the playback config.
   *
   * @param {PlaybackConfig} playbackConfig
   * @returns {Promise<void>}
   * @private
   */
  private async advertisingIntegration(playbackConfig: PlaybackConfig): Promise<void> {
    if (playbackConfig.adverts.length > 0) {
      return this.loadAdverts(playbackConfig.adverts, playbackConfig.duration, playbackConfig.playFrom);
    }
  }

  /**
   * De-dups and assigns an adverts for video(s).
   *
   * @param {Advert} advert
   * @private
   */
  private async loadAdvertisement(advert: Advert): Promise<void> {
    // should never happen but default to VAST if it does
    const adTagType = advert.type ? advert.type.toUpperCase() : 'VAST';
    const advertisement: AdBreakConfig = {
      id: advert.id,
      position: advert.position,
      tag: { url: advert.url, type: AdTagType[adTagType] },
    };
    // ensure we only load each advert once
    if (!this.adverts.find((ad) => ad.id === advertisement.id)) {
      // ensure the modules have loaded correctly
      if (this.player.ads) {
        await this.player.ads.schedule(advertisement);
        this.adverts.push(advertisement);
      } else {
        console.error('Advertising is not configured, correct modules not loaded');
      }
    }
  }

  /**
   * Steps through the array of adverts and conditionally loads each passed advert based on
   * the adverts assigned position and a possible resumed playFrom value, skipping adverts
   * will result in the last advert skipped to be played.
   *
   * @param {Advert[]} adverts
   * @param duration
   * @param playFrom
   * @private
   */
  private async loadAdverts(adverts: Advert[], duration: string, playFrom = 0): Promise<void> {
    const filteredAdverts = adverts
      .filter((advert) => Boolean(advert.position))
      .filter((advert) => {
        const value = this.parseAdvertPosition(advert.position, +duration);
        return !playFrom || value >= playFrom;
      });

    for (const ads of filteredAdverts) {
      await this.loadAdvertisement(ads);
    }
  }

  /**
   * Parses possible values for the position of an advert within a video
   * the method will transform a value (if required) into a consistent number format
   * acceptable position values are:
   * 'pre': pre-roll ad
   * 'post': post-roll ad
   * 'mid-roll' ad
   * fractional seconds: '10', '12.5'
   * percentage of the entire video duration: '25%', '50%' (mid-roll ad)
   * timecode [hh:mm:ss.mmm]: '00:10:30.000', '01:00:00.000' (mid-roll ad)
   *
   * @param {string} position
   * @param {number} duration
   * @returns {number}
   * @private
   */
  private parseAdvertPosition(position: string, duration: number): number {
    const value = position.toLowerCase();

    if (value.includes('pre')) {
      return 0;
    }

    if (value.includes('mid')) {
      return duration / 2;
    }

    if (value.includes('post')) {
      return duration;
    }

    if (value.includes('%')) {
      const percent = parseFloat(value.replace('%', ''));
      return (percent / 100) * duration;
    }

    if (value.includes(':')) {
      const [hours, minutes, seconds] = value.split(':').map(Number);
      return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    }
    return parseFloat(value);
  }
}